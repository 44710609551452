/* Define the sliding animation */
.modal-slide-in {
    animation: slideIn 0.5s forwards;
}

@keyframes slideIn {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Define the slide-out animation */
.modal-slide-out {
    animation: slideOut 0.5s forwards;
}

@keyframes slideOut {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
}

.modal-container {
    position: relative; /* Use position relative */
    z-index: 9999; /* Make sure it's a high value */
}

