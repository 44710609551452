
@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-white {
  background-color: #FFFFFE;
}
.card-white{
  background-color: #F8F1F1;
}

/*.btn {*/
/*  background: linear-gradient(*/
/*    92.23deg,*/
/*    #ff56f6 21.43%,*/
/*    #b936ee 50.63%,*/
/*    #3bace2 100%,*/
/*    #406aff 117.04%*/
/*  );*/
/*  box-shadow: 0px 4.42184px 107.23px rgba(255, 86, 246, 0.51);*/
/*}*/

/*.btn:hover {*/
/*  background: linear-gradient(*/
/*    92.23deg,*/
/*    #406aff 21.43%,*/
/*    #3bace2 50.63%,*/
/*    #b936ee 100%,*/
/*    #ff56f6 117.04%*/
/*  );*/
/*}*/


.btn {
  background-color: #007BFF;  /* Blue background color */
  box-shadow: 0px 4.42184px 20px rgba(0, 123, 255, 0.51);  /* Blue shadow */
  color: #ffffff;  /* White text color */
  font-family: 'Arial', sans-serif;  /* Use a conventional font */
  padding: 10px 20px;  /* Adjust padding for better spacing */
  border: none;  /* Remove border for a cleaner look */
  border-radius: 5px;  /* Add border-radius for rounded corners */
  cursor: pointer;  /* Change cursor on hover */
  display: inline-block;  /* Make the button an inline-block element */
}

.btn:hover {
  background-color: #0056b3;  /* Darker blue color on hover */
}

.btn i {
  margin-right: 5px;  /* Add some space between the text and icon */
}

.animated-list li {
  transition: transform 0.2s;
}

.animated-list li:hover {
  transform: scale(1.05);
}


.section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent 48%, #ffffff 52%, transparent 100%);
  background-size: 10px 100%;
}

.gradient,
.active {
  background: linear-gradient(to top, #30cfd0 0%, #330867 100%);
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
    color: #19456B;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#42A6E3] to-[#FF56F6] font-bold font-primary hover:from-[#FF56F6] hover:to-[#42A6E3];
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }

  @layer components {
    .text-input {
      @apply mt-2 mb-2 block w-full rounded border-2 border-gray-300 focus:border-yellow-400 focus:ring-0;
    }
  }
}

