.card{
    width: 30%;
    background-color:#8f979a;
    box-sizing: border-box;
    color:black;
}

@media (max-width: 640px) {
    .card {
        width: 100%;
    }
}

/* For middle screens (50% width) */
@media (min-width: 641px) and (max-width: 1024px) {
    .card {
        width: 45%;
    }
}